import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PortfolioItem} from "./PortfolioItem";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // public baseUrl = 'http://localhost/hodgedev/api.php';
  public baseUrl = 'hodgedev-backend/api.php';
  public portfolioItems: PortfolioItem[];
  public lastItem: PortfolioItem;
  constructor(private http: HttpClient) {

  }

  getPortfolioItems(): PortfolioItem[] {
    if(!this.portfolioItems) {
      this.getData().subscribe( items => {
        this.portfolioItems = items;
      });
    }

    return this.portfolioItems;
  }

  getData(): Observable<PortfolioItem[]> {
    return this.http.get<PortfolioItem[]>(this.baseUrl);
  }

  getItem(id: number): Observable<PortfolioItem> {
    return new Observable((observable) => {
      if (!this.portfolioItems) {
        this.getData().subscribe( portfolioItems => {
          this.portfolioItems = portfolioItems;
          for (let item of this.portfolioItems) {
            if (item.id == id) {
              this.lastItem = item;
              observable.next( this.lastItem);
              observable.complete();
            }
          }
        });
      } else {
        for (let item of this.portfolioItems) {
          if (item.id == id) {
            this.lastItem = item;
            observable.next( this.lastItem);
            observable.complete();
          }
        }
      }
    });
  }
}
