<div id="about-content">
  Brian Hodge

  <div id="about-content-body">
    <b>Full stack developer.</b>
    <br />
    Frontend, Backend, Systems, and Data.
    <br /><br />
    <a href="mailto:brian@hodgedev.com">brian@hodgedev.com</a>
  </div>
</div>
