<div id="main">
  <div id="about-content">
    Brian Hodge
    <div id="about-content-body">
      <b>Full stack developer.</b>
      <br />
      Frontend, Backend, Systems, and Data.
      <br /><br />
      <a href="mailto:brian@hodgedev.com">brian@hodgedev.com</a>
    </div>
  </div>
  <div id="home-content">
    Approach

    <div id="home-content-body">
      <b>verb</b>
      <br />
      Come near or nearer to (someone or something) in distance or time.
      <br /><br />
      <b>noun</b>
      <br />
      A way of dealing with something.
      <br /><br />
      No matter the language, platform, or project, approach is the most important step.  Conventions, standards, and best practices are the path to hitting deadlines with a quality project that meets and exceeds expectations.
    </div>
  </div>

  <div id="work-history">
    <div class="portfolio-header">
      No longer a front-end dev<br />
    </div>
    <div class="work-history-item">
      <div class="work-history-item-title">
        Roybi Robot<br /><a href="https://www.roybirobot.com/" target="_blank">https://www.roybirobot.com/</a>
      </div>
      <b>Director of Technology and Innovation</b><br /><br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After the sale of KIDSENSE to Roybi, from Kadho Inc. I was hired by Roybi to build a U.S. team.   I worked on their android companion version, as well as outlined how to fix some router connection issues.  Unfortunately, due to COVID-19, I was released after just two months.
    </div>
    <div class="work-history-item">
      <div class="work-history-item-title">
        Kadho Inc.<br /><a href="http://www.kadho.com/" target="_blank">http://www.kadho.com/</a>
      </div>
      <b>(Technical Director / Lead Developer)</b><br /><br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After a success with the Kadho Sports division, I moved to the parent company to help the company change direction.  We pivoted from B2C to B2B, and much had to be changed.  Together, with my team, we developed an easy to use and highly accurate SDK for offline (embedded) ASR aimed at children.  My role was to oversee the team, fix inefficiencies, optimization,  sdk design and packaging.  Some of my tasks included streamlining our low level libraries to easily build for different platforms and architectures, chasing down low level memory leaks in unmanaged code, optimization to reduce power consumption and memory leaks, and threading.  Outside of development, I was also responsible for systems.  Whether it be a move from Alibaba, to AWS, adding load balancing, and nix server setup, I had my hand in almost every aspect of the project.  We sold our main product "KIDSENSE" to Roybi Robot.
    </div>
    <div class="work-history-item">
      <div class="work-history-item-title">
        Kadho Sports<br /><a href="https://www.kadhosports.com/" target="_blank">https://www.kadhosports.com/</a>
      </div>
      <b>(Senior / Lead Developer)</b><br /><br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I joined the team in it's early stages and took over all development.  From the ground up, a new system had to be created.  I was responsible for the front-end, back-end, systems and data.  One of the great challenges was tackling the problem of low video framerates on old iPads.  Any plugins ready made for unity either required embedded content, unwanted formats, or less than desirable frame rates.  I spent a couple weeks developing our own plugin that met all of these such needs after learning a bit about opengl.  Since the software encompassed full screen video of sporting events where on the fly decisions had to be made, this was a must.  We quickly found clients in professional, college, and high school sports.  Once all core development and features for all requested platforms was completed, I moved on to the parent company Kadho Inc. to assume the title of Technical Director.
    </div>
    <div class="portfolio-header">
      The good ole Flash Days<br />
    </div>
    <a [routerLink]="['/home']" fragment="portfolio"></a>
    <div id="portfolio">
      <div class="portfolio-item" *ngFor="let pi of portfolioItems" (click)="gotoItem(pi.id)">
        <img src="assets/portfolio/{{pi.thumbnail}}" />
        <div class="portfolio-item-content">
          <div class="portfolio-item-title">
            {{pi.name}}
          </div>
          <div class="portfolio-item-body">
            <span *ngIf="pi.description.length >= 110">
              {{pi.description | slice:0:110}}...
            </span>
            <span *ngIf="pi.description.length < 110">
              {{pi.description}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
