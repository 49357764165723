import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api.service";
import {PortfolioItem} from "../PortfolioItem";
import {Router} from "@angular/router";

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  public portfolioItems: PortfolioItem[];
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.apiService.getData().subscribe( items => {
      this.portfolioItems = items;
    });
  }

  gotoItem(id: number) {
    this.router.navigate(['/portfolio', id]);
  }
}
