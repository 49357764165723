<div id="portfolio-detail-item-content">
  <div *ngIf="item" id="portfolio-detail-item-title">
    <span style="position: relative; top: 5px; cursor: pointer;" (click)="goBack()">
      <mat-icon>arrow_back_ios</mat-icon>
    </span>
    {{item.name}}
    <br />
    <div id="portfolio-detail-item-images">
      <div *ngFor="let im of item.images">
        <img src="assets/portfolio/{{im}}" />
      </div>
    </div>
  </div>
</div>
