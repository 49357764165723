import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  title = 'clockedfrontend';
  constructor(private router: Router) {
    console.log('Layout component started');
  }

  ngOnInit() {
    console.log('WHAT IN THE FUCK');
    // if (!ApiService.IS_LOCAL) {
    //   this.webService.checkSession().subscribe((user) => {
    //     if (user == null) {
    //       this.router.navigate(['/login']);
    //     } else if (!user.success || user.success.toString() === '0') {
    //       this.router.navigate(['/login']);
    //     }
    //   });
    // }

    // this.webService.checkSession().subscribe((user) => {
    //   console.log('WTF 1');
    //   if (user == null) {
    //     console.log('WTF 2');
    //     this.router.navigate(['/login']);
    //   } else if (!user.success || user.success.toString() === '0') {
    //     console.log('WTF 3');
    //     this.router.navigate(['/login']);
    //   }
    // });
  }
  gotoShoppingCart() {
    this.router.navigate(['/shopping-cart']);
  }
}

