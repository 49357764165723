import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api.service";
import {PortfolioItem} from "../PortfolioItem";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-portfolioitem',
  templateUrl: './portfolioitem.component.html',
  styleUrls: ['./portfolioitem.component.scss']
})
export class PortfolioItemComponent implements OnInit {
  public item: PortfolioItem;
  public id: number;
  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private router: Router) {

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.api.getItem(this.id).subscribe(item => {
        this.item = item;
      });
    });
  }

  goBack() {
    this.router.navigate(['/home'], {fragment: 'portfolio'})
  }
}
