import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import {LayoutComponent} from "./containers/layout";
import {FooterComponent} from "./footer/footer.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import { AboutComponent } from './about/about.component';
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import { PortfolioComponent } from './portfolio/portfolio.component';
import {HttpClientModule} from "@angular/common/http";
import {PortfolioItemComponent} from './portfolioitem/portfolioitem.component';

const APP_CONTAINERS = [
  LayoutComponent,
];

@NgModule({
  declarations: [
    ...APP_CONTAINERS,
    AppComponent,
    HomeComponent,
    FooterComponent,
    AboutComponent,
    PortfolioComponent,
    PortfolioItemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatTabsModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    BrowserAnimationsModule,
    HttpClientModule,
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
