<mat-toolbar class="mat-toolbar">
  <mat-toolbar-row>
<!--    <button class="menu-button" mat-icon-button (click)="sidenav.toggle()">-->
<!--      <mat-icon>menu</mat-icon>-->
<!--    </button>-->
    <span class="menu-spacer"></span>
    <div class="toolbar-links">
      <div id="logo">
        HD
      </div>
<!--      <div id="cart">-->
<!--        <cart-summary (click)="gotoShoppingCart()" [noItemsText]="'# items'" [oneItemText]="'# items'" [manyItemsText]="'# items'"></cart-summary><br />-->
<!--      </div>-->
<!--      <div id="link">-->
<!--        <a mat-button [routerLink]="'/login'">Login</a>-->
<!--      </div>-->
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container>
<!--  <mat-sidenav #sidenav>-->
<!--    <mat-nav-list>-->
<!--      <a mat-list-item [routerLink]="'/home'" (click)="sidenav.toggle()">-->
<!--        <mat-icon mat-list-icon>home</mat-icon>-->
<!--        <div class="nav-item">Home</div>-->
<!--      </a>-->
<!--      <a mat-list-item [routerLink]="'/about'" (click)="sidenav.toggle()">-->
<!--        <mat-icon mat-list-icon>info</mat-icon>-->
<!--        <div class="nav-item">About</div>-->
<!--      </a>-->
<!--      <a mat-list-item [routerLink]="'/portfolio'" (click)="sidenav.toggle()">-->
<!--        <mat-icon mat-list-icon>info</mat-icon>-->
<!--        <div class="nav-item">Portfolio</div>-->
<!--      </a>-->
<!--    </mat-nav-list>-->
<!--  </mat-sidenav>-->
  <mat-sidenav-content>
    <div id="main-bg" style="background-image: url('assets/home-background.jpg');">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

