import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {LayoutComponent} from "./containers/layout";
import {AboutComponent} from "./about/about.component";
import {PortfolioComponent} from "./portfolio/portfolio.component";
import {PortfolioItemComponent} from "./portfolioitem/portfolioitem.component";


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: "full"
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      title: 'Main'
    },
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      // {
      //   path: 'about',
      //   component: AboutComponent
      // },
      // {
      //   path: 'portfolio',
      //   component: PortfolioComponent
      // },
      {
        path: 'home/:id',
        component: PortfolioItemComponent
      }
    ]
  }
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled'
}

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
