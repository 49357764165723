import { Component, OnInit } from '@angular/core';
import {PortfolioItem} from "../PortfolioItem";
import {ApiService} from "../api.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public portfolioItems: PortfolioItem[];
  private fragment: string;
  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {this.fragment = fragment});
    this.apiService.getData().subscribe( items => {
      this.portfolioItems = items;
    });
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView(true);
      //window.scrollTo(0, -600);
    } catch (e) { }
  }

  gotoItem(id: number) {
    this.router.navigate(['/home', id]);
  }
}
